
.app__live-main {
    width: 100vw;
    max-width: 100vw;
    height: 100vh;
    background: #232531;
    overflow-y: auto;
    overflow-x: hidden;
    background-size: 100%;
    background-repeat: no-repeat;
    position: relative;

    &::-webkit-scrollbar {
        width: 8px;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background: #5e5e5e;
    }
}

.app__navbar {
    width: 100%;
    height: 48px;
    user-select: none;
    position: sticky;
    top: 0;
    right: 0;
    left: 0;
    z-index: 999;
    color: white;
    //backdrop-filter: saturate(180%) blur(20px);
    font-family: "SF-Pro", sans-serif;

    ul {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        list-style-type: none;
        gap: 40px;
        padding-inline-start: 0px;
        margin-inline: 10px;

        hr {
            position: absolute;
            border: none;
            width: 100%;
            height: 5px;
            background: #5b52f7;
            bottom: 1px;
        }

        li {
            position: relative;
            cursor: pointer;
            height: 100%;
            width: fit-content;
            display: flex;
            align-items: center;
        }
    }

    .app__navbar-logo {
        width: 30px;
        height: 30px;

        img {
            width: 100%;
            height: 100%;
        }
    }
}

@keyframes slideDown {
    from {
        transform: translateX(100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.app__navbar_menu {
    position: fixed;
    height: calc(100% - 48px);
    width: 100vw;
    backdrop-filter: blur(10px);
    z-index: 999;
    font-family: "SF-Pro", sans-serif;
    display: flex;
    justify-content: flex-end;
    .app__navbar_menu_inner {
        width: 70%;
        height: 100%;
        padding: 10px;
        background: #232531;
        color: white;
        position: relative;
        animation: slideDown 0.3s ease-out forwards;
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: -30px;
            width: 30px;
            height: 30px;
            //background: #0d2ff4;
            box-shadow: 11px -11px 0 #232531;
            border-radius: 50%;
        }
        p {
            position: absolute;
            bottom: 20px;
            right: 5px;
            font-size: .9rem;
            color: #787f99;
        }
        ul {
            width: 100%;
            list-style-type: none;
            padding-inline-start: 0px;
            position: relative;
            li {
                color: #f4f4f4;
                width: calc(100% - 20px);
                margin-bottom: 10px;
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-inline: 10px;
                cursor: pointer;
                font-size: 1.1rem;
                border-radius: 15px;
            }
            span {
                font-size: 1rem;
                font-weight: 200;
                font-family: "SF-Pro-Bold", sans-serif;
            }
        }
    }
}

.app__navbar_recent_routes {
    width: calc(100% - 20px);
    background: #fede67;
    border-radius: 15px;
    margin-top: 10px;
    padding: 10px;
    p {
        position: unset !important;
        color: #232531 !important;
        font-family: "SF-Pro-Bold", sans-serif;
        font-size: 1rem !important;
        margin-bottom: 10px;
    }
    li {
        background: #333645db;
        height: 40px !important;
        text-transform: capitalize;
    }
}


.app__live-footer {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #15161d;
    color: #707497;
    margin-top: 20px;
    font-family: "SF-Pro", sans-serif;
    padding-top: 50px;
    user-select: none;

    .app__live-footer-logo {
        width: 100%;
        max-width: 1600px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        img {
            width: 40px;
            height: 40px;
            filter:grayscale(100%) invert(100%);
        }
        span {
            font-family: "SF-Pro-Bold", sans-serif;
            font-size: 1.3rem;
            font-weight: 700;
        }
    }

    .app__live-footer-links {
        width: 100%;
        max-width: 1600px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 30px;
        a {
            cursor: pointer;
            color: #707497 !important;
            text-decoration: none;
        }
    }

    .app__live-footer-socials {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100px;
        width: calc(100% - 100px);
        max-width: 1600px;
        font-size: .85rem;
        .app__live-footer-socials_icons {
            display: flex;
            gap: 10px;
        }
    }
}

.app__waiting_for_conn {
    height: 70vh;
    width: 100%;
    display: flex;
    justify-content: center;
    background: #232531;
    .app__waiting_for_conn_inner {
        width: 100%;
        max-width: 1600px;
        height: 100%;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5px;
        font-family: "SF-Pro", sans-serif;
        h1 {
            font-size: 3rem;
        }
        p {
            font-size: .9rem;
            color: #cbcbcb;
        }
    }
}

.app__imageSlider {
    width: 100%;
    height: 100px;
}
.app__imageSlider_img {
    width: 100% !important;
    height: 100%;
    display: flex !important;
    align-items: center;
    justify-content: center;
    img {
        max-height: 90% !important;
        height: 70px;
        width: auto !important;
    }
}
.slick-slide {
    height: 100px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

@media (max-width: 2090px) {
    .app__background_opened {
        justify-content: flex-start;
    }
}
@media (max-width: 850px) {
    ::-webkit-scrollbar {
        display: none;
    }
    .app__navbar {
        ul {
            justify-content: space-between;
        }
    }
    .app__live-footer {
        height: 100px;
        background: none;
        span {
            width: calc(100% - 10px);
            background: #282b38;
            color: #545f72;
            height: 30px;
            border-radius: 10px;
            margin-bottom: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.8rem;
        }
    }
}
