$base_top1: #1b9e8f;
$base_top2: #232b39;
$base_top3: #232b39;

// Scoreboard leader row
.scoreboardRow-main {
    width: calc(100% - 10px);
    height: 100%;
    margin-inline: 5px;

    .scoreboardRow-head {
        width: 100%;
        height: 50px;
        background: #0b0b11;
        display: flex;
        align-items: center;
        justify-content: center;

        p {
            width: 78%;
            display: flex;
            justify-content: center;
            z-index: 10;
        }
        span {
            width: 22%;
            display: flex;
            justify-content: center;
            z-index: 10;
        }

    }
    .scoreboardRow-content {
        width: 100%;
        height: 120px;
        background: #0f0f17;
    }
    .scoreboardRow-footer {
        width: 100%;
        height: 30px;
        background: #0b0b11;
    }
}

.scoreboardRow-fastest-filler {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #0f0f18;
}

.scoreboardRow-competitors {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;

    .scoreboardRow-competitor-item{
        width: 24%;
        height: 100%;
        background: linear-gradient(to bottom, $base_top2 50%, #232b39);
        display: flex;
        position: relative;
        margin-left: 1%;

        .scoreboardRow-competitor-col-0{
            width: 20%;
            height: 100%;
            background: linear-gradient(to left, #13131d, #0f0f17);
            writing-mode: vertical-rl;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: "F1-Bold", sans-serif;
            font-size: 1.1rem;
            letter-spacing: 1.3px;
        }
        .scoreboardRow-competitor-row-2 {
            width: 80%;
            height: calc(100% - 45px);
            position: absolute;
            bottom: 0;
            left: 20%;

            display: flex;
            flex-direction: column;
            justify-content: center;
            font-family: "SF-Pro", sans-serif;
            overflow: hidden;
            text-overflow: ellipsis;

            p {
                margin-left: 10px;
                font-size: 1.1rem;

            }
            span {
                margin-left: 10px;
                font-size: 1.3rem;
                font-weight: 700;
                text-overflow: ellipsis;
                letter-spacing: 1.2px;
            }

            .scoreboardRow-competitor-row-2-change {
                position: absolute;
                top: 10px;
                right: 10px;

                img {
                    width: 20px;
                    height: auto;
                }
            }
        }
        .scoreboardRow-competitor-row-1 {
            width: 80%;
            height: 45px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: linear-gradient(to right, #13131d, #0f0f17);

            .scoreboardRow-competitor-col-1-pos{
                background: $base_top2;
                width: fit-content;
                padding-inline: 15px;
                height: 40px;
                border-top-right-radius: 10px;
                border-top-left-radius: 10px;
                display: flex;
                align-items: center;
                align-self: flex-end;
                justify-content: center;
                font-size: 1.2rem;
                font-family: "F1-Bold", sans-serif;
                position: relative;


                &::after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    right: -20px;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    box-shadow: -10px 10px 0 $base_top2;
                }
            }
            .scoreboardRow-competitor-col-1-nr {
                background: #0b0b11;
                height: 40px;
                width: 63%;
                align-self: flex-start;
                border-bottom-left-radius: 5px;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 3;
                font-family: "F1-Regular", sans-serif;

                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: -30px;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    box-shadow: 10px -10px 0 #0b0b11;
                }
            }
            .scoreboardRow-competitor-col-1-state {
                width: fit-content;
                height: 30px;
                img {
                    width: 48px;
                    height: 33px;
                }
            }
        }
    }
    .scoreboardRow-fastest-item {
        width: 24%;
        justify-self: flex-end;
        height: 100%;
        background: #4e22d4;
        display: flex;

        .scoreboardRow-fastest-col-1 {
            width: 20%;
            height: 100%;
        }
        .scoreboardRow-fastest-col-2 {
            width: 80%;
            height: 100%;
        }

        .scoreboardRow-fastest-row-2 {
            height: calc(100% - 45px);
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-family: "SF-Pro", sans-serif;
            overflow: hidden;
            text-overflow: ellipsis;

            .scoreboardRow-fastest-row-2-time {
                font-family: "F1-Bold", sans-serif;
                font-size: 1.1rem;
                color: #ffffff;
                letter-spacing: 1.2px;
                margin-left: 10px;
            }

            p {
                margin-left: 10px;
                font-size: 1.1rem;

            }
            span {
                margin-left: 10px;
                font-size: 1.3rem;
                font-weight: 700;
                text-overflow: ellipsis;
                letter-spacing: 1.2px;
            }
        }
        .scoreboardRow-fastest-row-1 {
            height: 45px;
            width: 100%;
            background: linear-gradient(to right, #0f0f17, #0b0b11);
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
                font-family: "F1-Bold", sans-serif;
                font-size: 1.2rem;
            }
            span {
                font-family: "F1-Regular", sans-serif;
            }
            .scoreboardRow-competitor-row-1-state{
                width: 48px;
                height: 33px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .scoreboardRow-fastest-row-1-content {
                width: fit-content;
                padding-inline: 20px;
                height: 40px;
                align-self: flex-end;
                background: #4e22d4;
                border-top-right-radius: 10px;
                border-top-left-radius: 10px;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: space-between;

                &::after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    right: -18px;
                    width: 18px;
                    height: 18px;
                    border-radius: 50%;
                    box-shadow: -10px 10px 0 #4e22d4;
                }
            }
        }
    }
}

// Flag popup
.flagPopup-main {
    position: absolute;
    top: 5px;
    left: 50%;
    width: 300px;
    height: 60px;
    background: #0b0b11;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    font-family: "F1-Regular";
    font-size: 1.4rem;
    font-weight: 700;
    letter-spacing: 1.2px;

    display: none;
    align-items: center;
    justify-content: center;

    transition: transform 1.5s ease-in-out;

}
.flagPopup-main.visible {
    display: flex;
    transform: translate(-50%, 0);
}
.flagPopup-main.hidden {
    transform: translate(-50%, -50%);
}