
.app_liveList-selected-loading {
    width: 100%;
    height: calc((15 * 40px) + 150px);
    background: #0f101a;
    color: #eeeeee;
    display: flex;
    align-items: center;
    justify-content: center;
}

.finish_flag {
    width: 25px !important;
    height: 25px !important;
}

.app__live-filter {
    width: calc(100% - 10px);
    max-width: 1600px;
    height: fit-content;
    padding-bottom: 5px;
    margin-inline: 5px;
    background: #232531;
}

@keyframes glowingEffect {
    0% {
      filter: brightness(100%);
    }
    50% {
      filter: brightness(120%);
    }
    100% {
      filter: brightness(100%);
    }
}

.app_liveList-selected-main {
    width: 100%;
    font-family: "Outfit", sans-serif;
    position: relative;
    transition: height 1s ease-in-out;

    .app_liveList-top-selector {
        width: 100%;
        height: 100px;
        background: #0c0c14;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        .app_liveList-color-selector-item {
            width: 120px;
            height: 80px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 5px;
            font-family: "SF-Pro", sans-serif;
            font-size: 0.9rem;
            color: azure;

            input {
                outline: 1px solid #2867d4;
                outline-offset: 3px;
                background: none;
                padding: 0;
                cursor: pointer;
                height: 50px;
                width: 80px;
            }
            input[type="color"]::-webkit-color-swatch-wrapper {
                padding: 0;
            }
            input[type="color"]::-webkit-color-swatch {
                border: none;
            }
        }
        .app_liveList-color-selector-btns {
            display: flex;
            flex-direction: column;
            gap: 5px;

            button {
                border: none;
                width: 150px;
                height: 36px;
                background: transparent;
                outline: 1px solid #2867d4;
                color: azure;

                &:hover {
                    cursor: pointer;
                    transform: scale(1.01);
                    background: #2867d4;
                    outline: none;
                }
            }
        }
    }
    .app_liveList-selected-results {
        width: 100%;
        height: fit-content;
        background: #232531;
        color: #eeeeee;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;


        .app__liveList-overflow-x-wrapper {
            overflow-x: auto;
            height: calc(100% - 150px);
            display: flex;
            flex-direction: column;
            white-space: nowrap;
            align-items: center;

            &::-webkit-scrollbar {
                height: 5px;
            }
            &::-webkit-scrollbar-track {
                background: #f1f1f1;
            }
            &::-webkit-scrollbar-thumb {
                background: #f2930e;
            }
        }

        header {
            width: calc(100% - 10px);
            background: #333645;
            max-width: 1600px;
            min-height: 150px;
            height: fit-content;
            display: flex;
            position: relative;
            font-family: "SF-Pro", sans-serif;
            margin-bottom: 3px;

            .app_liveList-selected-track-name {
                min-width: 350px;
                max-width: fit-content;
                min-height: 150px;
                height: 100%;
                margin-top: 10px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                display: flex;
                flex-direction: column;
                position: relative;

                p {
                    font-size: 2.1rem;
                    font-weight: 600;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin-left: 10px;
                }
                span {
                    margin-left: 10px;
                }
                .app_liveList-selected-track-type {
                    position: absolute;
                    width: fit-content;
                    height: 40px;
                    bottom: 5px;
                    left: 0px;
                    background: #232531;
                    display: flex;
                    align-items: center;
                    clip-path: polygon(0% 0%, 95% 0%, 100% 100%, 0% 100%);

                    p {
                        font-size: 1rem;
                        margin-left: 0px;
                        padding-left: 10px;
                        padding-right: 50px;
                        font-weight: 500;
                        color: white;
                    }

                    span {
                        width: fit-content;
                        height: 100%;
                        padding-inline: 15px;
                        background: #5d60ef;
                        display: flex;
                        font-size: 1.1rem;
                        align-items: center;
                        justify-content: center;
                        margin: 0;
                    }
                }
            }
            .app_liveList-selected-flag-and-chart{
                position: absolute;
                top: 10px;
                left: 40%;
                width: 20%;
                height: 40px;

                display: flex;
                align-items: center;
                justify-content: center;
            }
            .app_liveList-selected-time {
                position: absolute;
                bottom: 2px;
                left: 50%;
                width: 350px;
                transform: translate(-50%);
                height: 34px;
                font-weight: 800;
                letter-spacing: 1px;
                font-size: 1.3rem;

                p {
                    width: 100%;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
            .app_liveList-selected-time-flag-line {
                position: absolute;
                bottom: 0px;
                width: 100%;
                height: 4px;
            }

            .app_liveList-selected-time-flag-line-animated {
                animation: glowingEffect 2s infinite ease-in-out;
            }

            .app_liveList-selected-settings {
                position: absolute;
                right: 5px;
                top: 5px;
                width: 120px;
                height: 45px;

                display: flex;
                align-items: center;
                justify-content: flex-end;

                .app_liveList-selected-settings-btn {
                    width: 35px;
                    height: 35px;
                    background: transparent;
                    border: none;

                    &:hover {
                        transform: scale(0.96);
                        cursor: pointer;
                    }
                }
            }

            .app_liveList-selected-fullscreen {
                position: absolute;
                right: 5px;
                top: 55px;
                width: 120px;
                height: 40px;

                display: flex;
                align-items: center;
                justify-content: center;
                gap: 5px;

                border: none;
                background: #5b52f7;
                border-radius: 7px;
                color: #fff;
                font-size: 14px;
                font-weight: 400;

                &:hover {
                    cursor: pointer;
                    transform: scale(0.97);
                    background: #5b52f7;
                    transition: background 0.5s ease-in-out;
                }
            }
            .app_liveList-selected-viewers {
                position: absolute;
                right: -1px;
                bottom: 5px;
                width: 200px;
                height: 40px;
                background: #232531;
                clip-path: polygon(100% 0%, 5% 0%, 0% 100%, 100% 100%);
                font-family: "SF-Pro", sans-serif;

                display: flex;
                gap: 5px;

                p {
                    width: calc(75% - 5px);
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: white;
                }

                span {
                    width: 25%;
                    height: 100%;
                    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
                    color: white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 700;
                    font-size: 1.2rem;
                }
            }
        }
    }
}
.app__live_raceLeaders {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    .app__live_raceLeaders-inner {
        width: 1600px;
        height: 100%;
        background: #0f101a;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

.app__live-winner {
    width: 100%;
    max-width: 1600px;
    height: 60px;
    //background: #f1e046;
    //background: linear-gradient(to right, #1970ae, #16cbeb);
    background: linear-gradient(to left, #e1a326, #ddc226 80%, transparent);
    margin-bottom: 5px;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 50px;

    p {
        color: #1e1e1e;
        font-family: "F1-Bold", sans-serif;
        font-size: 1.4rem;
    }
}

.app__live-winner-mobile {
    width: calc(100% - 10px);
    min-height: 60px;
    height: fit-content;
    background: linear-gradient(to left, #e1a326, #ddc226);
    margin-bottom: 5px;
    position: relative;
}

.app__live-winner-mobile-element {
    transition: transform 1s ease-in-out;
    position: absolute;
    left: 0px;

    bottom: 0;
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 5px solid #0f101a;

    &:nth-child(1) {
        border-right: none;
    }

    p {
        color: #232531;
        font-family: "F1-Bold", sans-serif;
        font-size: 1.6rem;
    }
    .app__live-winner-mobile-info {
        z-index: 5;
        width: 100%;
        height: 100%;
        background: #232531;
        display: flex;
        align-items: center;
        gap: 10px;
        position: relative;

        .app__live-winner-mobile-nr {
            width: 50px;
            height: 50px;
            border-radius: 5px;
            background: white;
            color: #232531;
            margin-left: 10px;
            display: flex;
            align-items: center;
            outline: 1px solid azure;
            justify-content: center;
            font-family: "F1-Bold", sans-serif;
            font-size: 1.2rem;
        }
        .app__live-winner-mobile-state {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            gap: 3px;
            width: 35px;
            height: 100%;

            img {
                width: 35px;
                height: 26px;
            }
        }
        span {
            min-width: 160px;
            width: 40%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        p {
            position: absolute;
            right: 0;
            bottom: 0;
            padding-left: 20px;
            padding-right: 10px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            background: white;
            clip-path: polygon(100% 0%, 100% 100%, 0% 100%, 8% 0%);
            color: #232531;
            font-family: "SF-Pro", sans-serif;
            font-size: 1rem;
            font-weight: 700;
        }
    }
}
.slide-in {
    transform: translateX(0);
}
.slide-out {
    transform: translateX(-100%);
}


.app__live-winner-full {
    width: calc(100% - 10px);
    height: 60px;
    //background: #f1e046;
    //background: linear-gradient(to right, #1970ae, #16cbeb);
    background: linear-gradient(to left, #e1a326, #ddc226 80%, transparent);
    margin-bottom: 5px;
    margin-top: 5px;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 50px;
    color: white;
    font-family: "SF-Pro", sans-serif;

    p {
        color: #0f101a;
        font-family: "F1-Bold", sans-serif;
        font-size: 1.4rem;
    }
}

.app__live-winner-info {
    width: fit-content;
    height: 60px;
    background: #333645;
    padding-right: 50px;
    clip-path: polygon(100% 0%, 100% 100%, 0% 100%, 20px 0%);

    display: flex;
    align-items: center;

    .app__live-winner-nr {
        width: 80px;
        height: 100%;
        perspective: 1;
        background: white;
        clip-path: polygon(100% 0%, calc(100% - 20px) 100%, 0% 100%, 20px 0%);
        color: #121212;
        margin-left: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "F1-Bold", sans-serif;
        font-size: 1.2rem;
        outline: 1px solid #0f0f0f;
    }
    .app__live-winner-state {
        width: 40px;
        height: 30px;
        margin-left: 20px;

        img {
            width: 100%;
            height: 100%;
        }
    }
    span {
        font-size: 1.2rem;
        margin-left: 20px;
    }
    p {
        color: azure;
        font-family: "SF-Pro", sans-serif;
        padding-right: 20px;
        margin-left: 20px;
        font-size: 1rem;
    }
}

.app__Simulator {
    width: 100%;
    height: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-bottom: 50px;
    .simulator_inner {
        width: 1600px;
        height: 100%;
        background: #0f101a;

        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "F1-Regular", sans-serif;
    }
}
.app__selectedRace-table {
    width: 1600px;
    height: calc(15 * 38px);
    overflow-y: auto;
    overflow-x: auto;
    background: #0f101ac4;
    position: relative;

    &::-webkit-scrollbar {
        width: 0px;
        height: 7px;

    }
    &::-webkit-scrollbar-track {
        background: #0f101a;
    }
    &::-webkit-scrollbar-thumb {
        background: #2246bc;
    }

    font-family: "SF-Pro", sans-serif;
}

.app_liveList-settings-selector {
    width: 100%;
    height: 150px;
    background: #0c0c14;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: "SF-Pro", sans-serif;
}
.app__livelist-settings-buttons {
    width: 50%;
    min-width: 400px;
    height: 50%;
    display: flex;
    align-items: center;
    position: relative;
    background: #0f101a;

    button {
        margin-left: 5px;
        border: none;
        height: 40px;
        width: 250px;
        border-radius: 5px;
        background: #2246bc;
        color: white;
        cursor: pointer;

        &:hover {
            transition: background 0.5s ease;
            background: #1d9bf0;
        }
    }

    &::after {
        content: "";
        position: absolute;
        width: 20px;
        height: 20px;
        right: -20px;
        bottom: 0px;
        border-radius: 50%;
        box-shadow: -10px 10px 0 #0f101a;
    }
    &::before {
        content: "";
        position: absolute;
        width: 20px;
        height: 20px;
        left: -20px;
        bottom: 0px;
        border-radius: 50%;
        box-shadow: 10px 10px 0 #0f101a;
    }
}

.app_liveList-settings-group {
    width: 50%;
    min-width: 400px;
    height: 100px;
    background: #0f101a;
    color: azure;
    position: relative;

    header {
        font-size: 1.1rem;
        width: 100%;
        height: 30%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
    }
    .app_liveList-settings-items {
        width: 100%;
        height: 70%;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;
        gap: 5px;
        .app_liveList-settings-item {
            width: 32%;
            height: 28px;
            background: #0b0b11;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 0.9rem;
            transition: background 0.25s ease-in-out;

            span {
                margin-left: 10px;
                color: #3badf9;
                text-overflow: ellipsis;
                overflow: hidden;
                text-wrap: nowrap;
            }
            input {
                accent-color: #161825;
                width: 20px;
                height: 20px;
                border: none;
                outline: none;
                border-radius: 5px;
                margin-right: 10px;
            }
            &:hover {
                background: #2246bc;
            }
        }
    }
}

.app_liveList-selected-track-name-mobile {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 35px;

    p {
        font-size: 2rem;
        text-align: center;
        font-weight: 700;
        margin-top: 5px;
    }
    .track_mobile_live-details {
        width: 100%;
        height: 60px;
        background: #232531;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 5px;
        margin-bottom: 10px;
        color:azure;
        position: relative;

        hr {
            position: absolute;
            top: 50%;
            left: 0%;
            width: 100%;
            height: 2px;
            border: none;
            background: #1c1e28;
        }

        .track_mobile_live-details-item {
            width: 100%;
            height: 50%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
                font-size: 1rem;
                margin-left: 5px;
                font-weight: 500;
                color: white;
            }

            span {
                font-size: 1rem;
                margin-right: 5px;
                color: azure;
                z-index: 5;
            }
        }

        .track_mobile_live-details-top {
            position: absolute;
            width: 50%;
            height: 100%;
            right: 0;
            bottom: 0;
            background: #1c1e28;
            clip-path: polygon(100% 0%, 100% 100%, 0% 100%, 5% 0%);

            hr {
                position: absolute;
                top: 50%;
                left: 0%;
                width: 100%;
                height: 2px;
                border: none;
                background: #232531;
            }
        }

        span {
            margin-left: 10px;
        }
    }
}



@media (max-width: 1600px) {
    .app__liveList-selected-race-data {
        align-self: flex-start;
    }
    .app__liveList-selected-race-legend {
        align-self: flex-start;
    }
    .app_liveList-selected-results > header {
        margin-left: 0px !important;
    }
    .app__live-filter {
        width: 100vw;
    }
}

@media (max-width: 700px) {
    .app_liveList-selected-track-name {
        font-size: 0.9rem;
        justify-self: flex-start;
        p {
            font-size: 1.6rem !important;
        }
    }
}
@media (max-width: 450px) {
    .app_liveList-selected-track-name {
        font-size: 0.9rem;
        justify-self: flex-start;
        p {
            font-size: 1.4rem !important;
        }
    }
    .app_liveList-selected-fullscreen {
        width: 40px !important;
    }
}
