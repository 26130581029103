.app__historyRace-main {
    width: 100%;
    height: fit-content;
    font-family: "Outfit", sans-serif;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    header {
        width: 100%;
        max-width: 1600px;
        height: 250px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #f5f5f7;
        font-size: 60px;
        filter: blur(5px);

        font-family: "GT walsheim bold", sans-serif;
        letter-spacing: 0px;

        p {
            font-size: 20px;
            font-weight: 300;
            font-family: "SF-Pro", sans-serif;
            letter-spacing: 0;
        }
    }
}

.app_simulator {
    width: 100%;
    max-width: 1600px;
    height: 92px;
    background: linear-gradient(to top, #181f2e, transparent);
    .app_simulator_disclamer {
        font-size: .7rem;
        color:#3a84a1;
        margin-left: 40px;
        margin-top: 5px;
    }
    .app_simulator_content {

        width: 100%;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        .app_simulator_lap {
            height: 100%;
            overflow-x: hidden;
            position: relative;
        }
        .app_simulator_lap_number {
            position: absolute;

            width: calc(100% - 2px);
            height: 29px;
            display: flex;
            align-items: center;
            justify-content: center;
            span {
                padding: 2px 10px;
                background: #232e46;
                border-radius: 5px;
            }
        }
        .app_simulator_lap_line {
            width: 100%;
            height: 5px;
            position: relative;
            margin-top: 43px;
            background: #232531;
            border-top: 1px solid #a1a1a1;
            border-bottom: 1px solid #a1a1a1;
            .app_simulator_finish_flag {
                position: absolute;
                right: 0px;
                width: 6px;
                top: -8px;
                height: 22px;
                background: repeating-conic-gradient(#f2f0f0 0% 25%, #000000 0% 50%) 50% / 4px 4px;
            }
            span {
                position: absolute;
                right: 10px;
                top: -1px;
                width: 3px;
                height: 7px;
                background: white;
            }
        }
    }
}
.app_simulator_competitor_group {
    width: fit-content;
    height: 26px;
    padding-inline: 3px;
    background: white;
    color: #111a27;
    transition: left 1s linear, background 0.5s ease-in-out;
    display: flex;
    align-items: center;
    gap: 3px;
    top: -10px;
    border-radius: 4px;
    cursor: pointer;
}

.app_simulator_competitor {
    position: absolute;
    top: -15px;
    width: 34px; /* Adjust size as needed */
    height: 34px;
    border-radius: 50%;
    border: 1px solid #000; /* Outer circle border color */
    display: flex;
    align-items: center;
    justify-content: center;
    background: #333645;
    transition: left 1s linear;
}

.app_simulator_competitor_inner {
    width: 26px; /* Adjust size as needed */
    height: 26px;
    border-radius: 50%;
    background: #111a28;
    color: #fff; /* Text color */
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px; /* Adjust font size as needed */
}

.app_simulator_tooltip {
    position: absolute;
    color: white;
    width: fit-content;
    padding-inline: 3px;
    gap: 3px;
    display: flex;
    height: 30px;
    top: -22px;
    right: 50%;
    transform: translateX(50%);
    .app_simulator_tooltip_competitor {
        width: fit-content;
        height: 20px;
        padding-inline: 4px;
        border-radius: 3px;
        text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    }
}

.packet_monitor {
    max-width: 1600px !important; // Set the maximum width here
    width: 100%;
    height: 100px;
    background: #232531;
    overflow: hidden;
    margin: 0 auto;
    margin-bottom: 5px;

    canvas {
        width: 100%;
        height: 100%;
    }
}

@keyframes dots {
    0%, 20% {
        content: '';
    }
    40% {
        content: '.';
    }
    60% {
        content: '..';
    }
    80%, 100% {
        content: '...';
    }
}

.animated-dots::after {
    content: '';
    animation: dots 1s steps(5, end) infinite;
}

.resizable_table_waiting_data {
    max-width: 100%;
    width: 1600px;
    height: 610px;
    background: linear-gradient(to bottom, #333645, transparent);
    position: relative;
    p {
        position: absolute;
        top: 20%;
        left: 46%;
    }
}

.resized_retired_position {
    background: #ff5656;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: 700;
    font-size: 1.1rem;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.resizable-table-filter-row {
    width: 100%;
    height: 0;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background: transparent;

    .resizable-table-filter-selector {
        width: 100%;
        max-width: 1600px;
        height: 100%;
        margin-top: 3px;
        color: azure;
        z-index: 300;

        p {
            margin-bottom: 3px;
            font-family: "SF-Pro", sans-serif;
        }
    }
}

.mobile_filter-height {
    height: fit-content;
}
.filter-height-100 {
    height: min-content !important;
}

.app__historyRace-table-wrapper-load {
    width: 100%;
    height: 100%;
    background: #232531;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
}

.load-wheel {
    width: 20%;
    height: 350px;
}

.app__historyRace-table-wrapper {
    width: 100%;
    height: fit-content;
    min-height: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background: #232531;

    &::-webkit-scrollbar {
        height: 7px;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background: #5e5e5e;
    }
}

.mobile__historyRace-data-portait-info {
    width: 100vw;
    height: 30px;
    margin-bottom: 5px;
    background: #333645;
    font-size: 0.85rem;
    text-align: center;
    display: flex;
    color: azure;
    p {
        width: calc(100% - 30px);
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
    }
}
.mobile__historyRace-data-non-portait-info {
    width: 100vw;
    height: 30px;
    font-size: 0.85rem;
    text-align: center;
    display: flex;
    color: azure;
    p {
        width: calc(100% - 30px);
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
    }
}

.mobile__historyRace-data-portait-info-close {
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.mobile__historyRace-table-settings {
    width: 100vw;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.historyRace-settings-50 {
    height: 50px !important;
}
.app__historyRace-settings {
    height: 0;
    width: 100%;
    display: flex;
    overflow: hidden;
    transition: height 0.25s ease-in-out;
    justify-content: center;

    .app__historyRace-settings-selector {
        max-width: 250px;
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 3px;
        padding-inline: 10px;

        input[type="color"] {
            outline: 1px solid #2f3462;
            background: none;
            padding: 0;
            cursor: pointer;
            height: 40px;
            width: 80px;
            border-radius: 5px;
        }
        input[type="color"]::-webkit-color-swatch-wrapper {
            padding: 0;
        }
        input[type="color"]::-webkit-color-swatch {
            border: none;
        }
    }
}

.mobile__historyRace-data {
    width: calc(100vw);
    height: fit-content;
    background: #333645;
    color: azure;
    display: flex;
    flex-direction: column;
    align-items: center;

    .app__historyRace-data-top {
        width: 95%;
        margin-bottom: 5px;

        .app__historyRace-data-top-event-data {
            width: 100%;
            min-height: 70px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            margin-left: 5px;
            span {
                font-size: 2rem;
                font-weight: 700;
            }
            p {
                font-size: 0.9rem;
                color: #51d6ff;
            }
        }
        .app__historyRace-data-top-track-data {
            width: 100%;
            min-height: 70px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            margin-right: 10px;
            span {
                font-size: 1rem;
            }
            p {
                display: flex;
                align-items: center;
                gap: 5px;
                font-size: 1.1rem;
            }
        }
    }
    .app__historyRace-data-bottom {
        width: 100%;
        min-height: 60px !important;
        background: #232531;

        .mobile__historyRace-data-race-winners {
            width: calc(100vw - 10px);
            margin-left: 5px;

            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 5px;
            padding-block: 5px;

            .app__historyRace-data-winner {
                width: 100%;
                height: 67.5px;
                background: linear-gradient(to right, #2549c0, #0d30a3);
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;

                h4 {
                    font-size: 1.13rem;
                    font-family: "SF-Pro", sans-serif;
                }
            }
            .app__historyRace-data-fastest-lap {
                width: 100%;
                height: 67.5px;
                background: linear-gradient(to right, #5107b2, #7c07b2);
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;

                h4 {
                    font-size: 1.13rem;
                    font-family: "SF-Pro", sans-serif;
                }
            }
        }
    }
}
.non-resizable_table_head {
    height: 30px;
    background: #787f99;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9rem;
}

.mobile__resiazble_item {
    height: 100%;
    display: flex;
    align-items: center;
    border-right: 1px solid #1f212c;
    transition: background-color 1s ease;

    p {
        overflow: hidden;
        text-wrap: pretty;
        text-overflow: ellipsis;
    }
    span {
        overflow: hidden;
        text-wrap: pretty;
        text-overflow: ellipsis;
    }

    &:last-child {
        border-right: none;
    }
}

.app__historyRace-data {
    width: 1600px;
    background: #333645;
    // background: #000000e0;
    color: azure;

    .app__historyRace-data-bottom {
        width: 100%;

        .app__historyRace-data-race-winners {
            width: 100%;
            height: fit-content;
            display: flex;
            margin-bottom: 1px;
            background: #232531;

            .app__historyRace-data-winner {
                height: 60px;
                padding-inline: 30px;
                background: linear-gradient(to right, #2549c0, #0d30a3);
                clip-path: polygon(0% 0%, 100% 0%, calc(100% - 10px) 100%, 0% 100%);
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;

                h4 {
                    font-size: 1.13rem;
                    font-family: "SF-Pro", sans-serif;
                }
            }
            .app__historyRace-data-fastest-lap {
                height: 60px;
                padding-inline: 30px;
                background: linear-gradient(to right, #6e1bdc, #8c18c1);
                clip-path: polygon(10px 0%, 100% 0%, calc(100% - 10px) 100%, 0% 100%);
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;

                h4 {
                    font-size: 1.13rem;
                    font-family: "SF-Pro", sans-serif;
                }
            }
        }
        .app__historyRace-data-bottom-filter {
            width: 100%;
            height: auto;
            margin-bottom: 2px;
            position: relative;

            .app__historyRace-data-bottom-settings-toggle {
                position: absolute;
                right: 10px;
                top: -60px;
                width: 60px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                cursor: pointer;
            }
            .app__historyRace-data-bottom-resbyclass-toggle {
                position: absolute;
                right: 70px;
                top: -60px;
                width: 120px;
                height: 58px;
                background: #0b0b11;
                outline: 1px solid #21212d;
                color: azure;
                border: none;
                text-align: center;
                transition: background 0.5s ease-in-out;
                cursor: pointer;
                &:hover {
                    background: #3d22d4;
                }
            }
            .app__historyRace-data-bottom-filter-toggle {
                position: absolute;
                right: 10px;
                top: -30px;
                width: 60px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                cursor: pointer;
            }
            .mobile_resizable-table-filter-row {
                width: 90%;
                height: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                transition: height 0.25s ease-in-out;
                gap: 5px;

                .mobile_resizable-table-filter-selector {
                    width: 90%;
                    max-width: 90%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 3px;
                    color: azure;
                    .mobile_resizable-select {
                        width: 100% !important;
                    }
                }
            }
        }
    }

    .app__historyRace-data-top {
        width: 100%;
        height: 90px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .app__historyRace-data-top-event-data {
            margin-left: 5px;
            span {
                font-size: 2rem;
                font-weight: 700;
            }
            p {
                font-size: .9rem;
                color: #51d6ff;
            }
        }
        .app__historyRace-data-top-track-data {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            margin-right: 20px;
            p {
                display: flex;
                align-items: center;
                gap: 10px;
                font-size: 2rem;
                font-weight: 700;
            }
            span {
                font-size: .9rem;
                color: #51d6ff;
            }
        }
    }
}

.app__historyRace-table_overflow_wrapper {
    max-width: 1600px;
    width: 100%;
    min-height: calc(15 * 38px);
    max-height: 80vh;
    overflow: auto;
    margin-right: 5px;

    &::-webkit-scrollbar {
        width: 0px;
        height: 12px;

    }
    &::-webkit-scrollbar-track {
        background: #1e1e1e;
    }
    &::-webkit-scrollbar-thumb {
        background: #393838;
    }
}

.app__historyRace-table {
    background: #232531;
    max-width: 1600px;
    position: relative;
    padding-bottom: 20px;
    overflow: auto;

    font-family: "SF-Pro", sans-serif;

    &::-webkit-scrollbar {
        height: 8px;
    }
    &::-webkit-scrollbar-track {
        background: #c6c6c6;
    }
    &::-webkit-scrollbar-thumb {
        background: #5e5e5e;
    }

    .app__historyRace-table-inside-loader {
        width: 100%;
        height: 100%;
        color: azure;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        overflow-x: auto;
    }
    .mobile__historyRace-table-inside-loader {
        width: 100vw;
        height: 100%;
        color: azure;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .app__historyRace-table-inside-mobile {
        min-width: calc(100% - 10px);
        width: fit-content;
        color: azure;
        display: flex;
        flex-direction: column;
        gap: 2px;
        margin-inline: 5px;
    }

    .app__historyRace-table-inside {
        width: fit-content;
        color: azure;
        display: flex;
        flex-direction: column;
        gap: 1px;
    }
}

.resizable_wrapper {
    display: flex;
    width: 100%;
    gap: 1px;
    position: sticky;
    top: 0;
    z-index: 50;
}
.resizable_wrapper_mobile {
    display: flex;
    width: 100%;
    gap: 1px;
    position: sticky;
    overflow-x: auto;
    z-index: 6;
    top: 0;
    transition: background-color 1s ease;
    background: #000;
}
.resizeable_content-wrapper {
    display: flex;
    gap: 1px;
}

.resizeable_table_element {
    display: flex;
    flex-direction: column;
    background: #202329;
}

@media (max-width: 1620px) {
    .app__historyRace-table {
        align-self: flex-start;
    }
    .app__historyRace-data {
        align-self: flex-start;
        width: 100vw;
    }
    .app__historyRace-table-data-selection {
        width: 100vw;
        border-radius: 0px;
    }
    .app__historyRace-table-data-selection-tab {
        width: 50vw;
    }
}

.mobile__resiazble_content {
    height: 52px;
    width: fit-content;
    margin-bottom: 1px;
    position: relative;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;

    img {
        width: 40px;
        height: 30px;
    }
}

.mobile__resizable_item_percentage_container {
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 2px;
    overflow: hidden;
    z-index: 5;
    transform: translateX(calc(100% - 100vw));
}

.mobile__resizable_item_percentage {
    position: relative;
    width: 100%;
    background: #1d1f28;
    z-index: 1;
    .mobile__resizable_item_percentage_inner {
        height: 2px;
        background: #2febf5;
        transition: width 1s linear;
    }
}

.mobile__resizable_item_flexbox {
    width: 100%;
    height: 50px;
    display: flex;
}

.resizable_col_inner {
    display: flex;
    flex-direction: column;
}
.resizeable_table_head {
    background: #787f99;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 200 !important;

    p {
        z-index: 10;
        width: 100%;
        font-weight: 700;
        letter-spacing: 0.5px;
        text-align: center;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        text-transform: capitalize;
        text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;

        &:hover {
            cursor: move;
        }
    }

}

.resizable_item {
    background: #131417;
    height: 30px;
    margin-top: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: background-color 1s ease;

    hr {
        width: 1px;
        height: 80%;
        border: none;
        background: #5f5f5f;
    }

    p {
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin-inline: 5px;
        text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    }

    img {
        width: 36px;
        height: 25px;
    }
}

@keyframes flashAnimation {
    0%, 100% { opacity: 1; }
    50% { opacity: 0.5; }
}

.resized_percentage {
    width: 100%;
    height: 100%;
    background: #232531;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;

    .flashing {
        animation: flashAnimation 2s infinite;
    }

    .resized_percentage_box {
        width: 7%;
        height: 15px;
        background: #090d13;
        transition: background 1s linear;
        &:nth-child(1) {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            width: 12%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &:last-child {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            width: 12%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        p {
            font-size: .7rem;
            margin: 0;
        }
    }

}

.resized_position_class {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    font-size: .9rem;

    text-overflow: ellipsis;
    overflow: hidden;
    .resized_position_class_color {
        width: 10px;
        height: 30px;
    }
}

.resized_position_last5 {
    width: 95%;
    height: 90%;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background: #0c0c11;
    p {
        width: 19%;
        height: 90%;
        border-radius: 5px;
        transition: background 1s ease-in-out;
    }
}


.resized_position_surname {
    font-weight: 800;
    font-size: 1.23rem;
    font-family: "SF-Pro-Bold", sans-serif;
}
.resized_position_surname_mobile {
    font-weight: 700;
    font-size: 1.06rem;
    font-family: "SF-Pro-Bold", sans-serif;
    margin-inline: 5px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
.resized_position_firstname {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    text-align: start;
    overflow: hidden;
    font-size: 1rem;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    font-family: "SF-Pro", sans-serif;
}
.resized_position_wrapper {
    width: 36px;
    height: 33px;
    background: azure;
    border-bottom-right-radius: 10px;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 1.2rem;
}
.resized_position_wrapper_mobile {
    width: 100%;
    height: 100%;
    padding-inline: 5px;
    background: #333645;
    display: flex;
    justify-content:space-between;
    align-items: center;
    font-weight: 700;
    font-family: "F1-Regular", sans-serif;
}
.resized_start_nr {
    font-weight: 700;
    color: #85a0ff;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.15rem;
}

.resized_speed {
    height: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;

    p {
        font-weight: 600;
        font-size: 1.05rem;
    }
    span {
        color: white;
    }
}

.resizable_item_fastest_lap_mark {
    position: absolute;
    left: 0;
    top: 0;
    height: 50px;
    width: 4px;
    background: #7727de;
}

.resized_speed_full {
    height: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    gap: 5px;

    p {
        font-weight: 600;
        font-size: 1.05rem;
    }
    span {
        color: white;
    }
}

.resizable_ghost_block {
    height: 30px;
    background: #393841;
    cursor: move;

    display: flex;
    align-items: center;
    justify-content: center;

}
.resizable-ghost-content {
    background-image: repeating-linear-gradient(
        45deg,
        #232531, /* Blue */
        #232531 20px,
        #333645 20px, /* Red */
        #333645 40px
      );
    cursor: no-drop;
    z-index: 400;
}
.resiazble_class_header {
    height: 23px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    position: relative;
    p {
        white-space: nowrap;
        overflow: visible;
        position: absolute;
        z-index: 5;
        font-size: 1.2rem;
    }
    .resizable_class_header_decoration {
        position: absolute;
        overflow: visible;
        z-index: 5;
        width: 500px;
        height: 40px;
        border-left: 2px solid white;
        border-top: 2px solid white;
        border-top-left-radius: 5px;
        left: 10px;
    }
}

.app__historyRace-table-data-selection {
    background: #232531;
    width: calc(100% - 10px);
    max-width: 1600px;
    height: 50px;
    display: flex;
}

.app__historyRace-table-data-selection-tab {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: azure;
    position: relative;
    font-family: "SF-Pro", sans-serif;
    letter-spacing: 0.6px;

    &:first-child {
        &::after {
            content: "";
            position: absolute;
            right: -20px;
            bottom: 0px;
            height: 20px;
            width: 20px;
            border-radius: 50%;
        }
        border-top-right-radius: 10px;
    }
    &:last-child {
        &::before {
            content: "";
            position: absolute;
            left: -20px;
            bottom: 0px;
            height: 20px;
            width: 20px;
            border-radius: 50%;
        }
        border-top-left-radius: 10px;
    }
}
.resultsByClassTrue {
    background: #333645;
    &::after {
        box-shadow: -10px 10px 0 #333645;
    }
}

.resultsByClassFalse {
    background: #333645;
    &::before {
        box-shadow: 10px 10px 0 #333645;
    }
}
.hoverTab {
    &:hover {
        background: #787f99;
        color: white !important;
        width: calc(50% - 10px) !important;
        height: calc(100% - 5px) !important;
        border-radius: 10px;
        margin-inline: 5px;
        cursor: pointer;
    }
}

.not-real-results {
    width: 100%;
    max-width: 1600px;
    height: 30px;
    background: #333645;
    color: #4288e4;
    font-size: 0.75rem;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    p {
        margin-right: 20px;
    }
}

.app__historyRace-navbar-infobox {
    width: 100%;
    max-width: 1600px;
    min-height: 50px;
    height: fit-content;

    background: #232531;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .app__historyRace-table-route {
        height: 100%;
        min-height: 30px;
        width: fit-content;
        display: flex;

        align-items: center;
        gap: 5px;
        color:#3bb7dc;
        margin-left: 5px;
        p {
            background: #333645;
            padding: 3px 10px;
            border-radius: 5px;
            text-wrap: nowrap;
            cursor: pointer;
            font-size: clamp(12px, 0.9vw, 15px);
        }
    }
    .app__historyRace-table-share {
        width: fit-content;
        height: 40px;

        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-wrap: wrap;
        gap: 20px;
        margin-right: 20px;
    }
}

@keyframes changeColorGreen {
    0% {
      background: none !important; /* Initial color */
    }
    50% {
      background: greenyellow !important; /* Color change to green */
    }
    100% {
      background: none !important; /* Back to the initial color */
    }
}
@keyframes changeColorRed {
    0% {
      background: none !important; /* Initial color */
    }
    50% {
      background: red !important; /* Color change to green */
    }
    100% {
      background: none !important; /* Back to the initial color */
    }
}

:root {
    --prim: #2c2f3e;
    --second: #232531;
    --flash: #333645;
}

.flash_resizable_item_green {
    background: #355e3e !important;
}
.flash_resizable_item_red {
    background: #7d2727 !important;
}
.flash_resizable_item_finish {
    background: #4a4c52 !important;
}
.flash_resizable_item_custom-prim {
    background: var(--prim) !important;
}
.flash_resizable_item_custom-second {
    background: var(--second) !important;
}
.flash_resizable_item_custom-flash {
    background: var(--flash) !important;
}

// Light mode
.flash_resizable_item_finish_light {
    background: #cacaca !important;
}
.flash_resizable_item_custom-prim_light {
    background: #f5f5f7 !important;
}