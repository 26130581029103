.app_liveList-main {
    width: 100%;
    height: fit-content;
    font-family: "Outfit", sans-serif;
    background-repeat: no-repeat;
    background-size: cover;
    background: #232531;
    user-select: none;

    display: flex;
    flex-direction: column;
    align-items: center;

    .app_liveList-seperator {
        width: 100%;
        max-width: 1600px;
        height: 100px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top;
        background-color: transparent;
    }

    header {
        width: 100%;
        max-width: 1600px;
        min-height: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 40px;
        font-weight: 900;
        text-align: center;
        padding-bottom: 10px;

        font-family: "GT walsheim bold", sans-serif;
        letter-spacing: 0px;

        h1 {
            font-size: 3rem;
        }

        p {
            font-size: 14px;
            font-weight: 300;
            font-family: "SF-Pro", sans-serif;
            letter-spacing: 0;
            color: #9aabc9;
        }
    }
    .app_liveList-selector-wrapper {
        width: 100%;
        height: 44px;
        display: flex;
        justify-content: center;
        margin-bottom: 2px;

    }
    .app_liveList-selector {
        width: 80%;
        min-width: 480px;
        max-width: 1200px;
        height: 100%;
        background: #1f2223;
        display: flex;
        position: relative;

        .app_liveList-selector-item {
            height: 100%;

        }
    }
    .app__liveList-selector-filters {
        position: absolute;
        width: 250px;
        height: 40px;
        left: 0;
        top: -40px;
        background: #4a09f0;

        display: flex;
    }
    .app_liveList-content {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        background: transparent;

        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2px;

        .app_liveList-item {
            width: 80%;
            min-width: 480px;
            max-width: 1200px;
            height: 40px;
            background: #131516;
            color: #fff;
            position: relative;
            display: flex;

            &:hover {
                background: #4a09f0;
            }
            .app__liveList-item-race-name {
                width: 50%;
                height: 100%;
                border-right: 1px solid #504d4d;
            }
            .app_liveList-item-name {
                width: 30%;
                height: 100%;
                display: flex;
            }
            button {
                position: absolute;
                right: 0px;
                top: 0px;
                width: 50px;
                height: 40px;
                border: none;
                background: #4a09f0;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}