.lds-ring {
    display: inline-block;
    position: relative;
    width: 100px;
    height: 100px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 90px;
    height: 90px;
    margin: 0px;
    background: linear-gradient(#151f31, #151f31) padding-box,
              linear-gradient(to right, #04faa4, #1de6a0, #0e83d6) border-box;
    border: 20px solid transparent;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    //border-color: #0f101a transparent #0f101a transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }


.__board_table_wrapped {
    width: 100%;
    max-width: 1600px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &::-webkit-scrollbar {
        height: 7px;
    }
    &::-webkit-scrollbar-track {
        background: #2e326b;
    }
    &::-webkit-scrollbar-thumb {
        background: #2f53e4;
    }
}

.__board_table_no_data {
    width: 100%;
    height: 100%;
    min-height: 700px;
    color: aliceblue;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    text-align: center;
}
.__board_table_data_loading {
    width: 100%;
    height: 100%;
    min-height: 700px;
    color: aliceblue;

    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.__board_table_filter {
    width: calc(100% - 10px);
    margin-left: 5px;
    max-width: 1600px;
    display: flex;

    font-family: "SF-Pro", sans-serif;
    margin-bottom: 10px;

}

.__board_table_filter_items {
    width: 100%;
    height: 100%;
    padding: 5px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    gap: 10px;
    hr {
        border: none;
        width: 1px;
        height: 30px;
        background: #9fb1cf;
        margin-left: 10px;
    }
    span {
        color:#9fb1cf;
        text-transform: uppercase;
        font-size: .85rem;
    }

    .select_field_wrapper_ {
        color: #eeeeee;
        width: 150px;
    }
}

.__board_table_filter_name_filter {
    width: fit-content;
    color: azure;
    position: relative;
    display: flex;
    align-items: center;

    .search-icon {
        position: absolute;
        left: 5px;
        pointer-events: none;
    }

    input {
        height: 38px;
        width: 100%;
        outline: none;
        border: none;
        padding: 0px;
        padding-inline: 5px;
        padding-left: 35px;
        border-radius: 2px;
    }
}

.__board_table_date_sorters {
    height: 38px;
    width: fit-content;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    gap: 5px;

    span {
        margin-right: 10px;
    }

    button {
        display: flex;
        align-items: center;
        gap: 5px;
        padding-inline: 10px;
        height: 100%;
        border: none;
        border-radius: 2px;
        color: #fff;
        cursor: pointer;
        transition: background 0.3s ease-in-out;
    }
}


.__board_table {
    max-width: 1600px;
    width: 100%;
    padding-inline: 5px;
    height: 100%;
    font-family: "SF-Pro", sans-serif;
    overflow: auto;

    &::-webkit-scrollbar {
        height: 5px;
        width: 5px;
    }

    &::-webkit-scrollbar-track{
        background: #c6c6c6;
    }
    &::-webkit-scrollbar-thumb {
        background: #5e5e5e;
    }
}

.__board_table_legend {
    width: 100%;
    min-width: fit-content;
    height: 30px;
    display: flex;
    align-items: center;

    color: white;
    font-weight: 400;
    font-family: "SF-Pro", sans-serif;
    margin-bottom: 5px;

    .__board_table_legendField {
        display: flex;
        height: 100%;
        align-items: center;
        font-size: 0.93rem;
        color: #9fb1cf;
        text-transform: uppercase;
    }
}
.__board_table_data {
    height: calc(15 * 38px);
    width: 100%;

    &::-webkit-scrollbar {
        display: none;
    }

    .__board_table_data_item {
        width: calc(100% - 5px);
        height: 50px;
        color: white;
        margin-top: 1px;
        display: flex;
        align-items: center;
        background: transparent;
        transition: background 0.3s ease-in-out;

        &:nth-child(1) {
            margin-top: 1px;
        }
        &:hover {
            cursor: pointer;
            background: #2c2f3e;
        }

        p {
            display: flex;
            align-items: center;
            height: 100%;
            text-overflow: ellipsis;
            text-wrap: nowrap;
            overflow: hidden;
            padding-right: 5px;
            span {
                max-width: 100%;
                font-size: 1.05rem;
                //margin-inline: 5px;
                text-overflow: ellipsis;
                text-wrap: nowrap;
                overflow: hidden;
            }
        }

        img {
            border-radius: 10%;
        }
    }
}

@keyframes online_pulse {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
}

.__board_table_race_server {
    width: 100%;

    display: flex;
    align-items: center;

    span {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: 2px solid #10f066;
        background: #232531;
        margin-right: 5px;
        h4 {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            margin-top: 2px;
            margin-left: 2px;
            background: #10f066;
            animation: online_pulse 2s infinite;
        }
    }
}

.extension {
    width: 100%;
    height: 300px;
    background: #000b07;
}

.__board_table_flag_element {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    .__board_table_flag {
        width: 90%;
        height: 26px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    }
}
@media (max-width: 1620px) {
    .__board_table {
        align-self: flex-start;
        min-width: 100%;
    }
    .__board_table_filter {
        align-self: flex-start;
        min-width: 100%;
    }
}

@media (max-width: 500px) {
    .__board_table_filter_name_filter {
        width: 80vw;
    }
    .__board_table_filter_items {
        hr {
            display: none;
        }
    }
}

@media (max-width: 850px) {
    .__board_table_filter {
        background: #2c2f3e;
        width: calc(100% - 10px) !important;
        min-width: auto;
        margin: 0;
        padding: 0;
        margin-left: 5px;
        padding-top: 10px;
        margin-bottom: 15px;
        border-radius: 15px;

        hr { display:  none; }

        .__board_table_filter_items {
            gap: 5px;
        }

        .__board_table_filter_name_filter {
            width: calc(100% - 40px);
        }

        input {
            background: #21242d;
            border-radius: 10px;
            color: white;
        }
    }
    .__board_table_date_sorters {
        width: 100%;
        justify-content: center;
        background: #232531;
        border-radius: 10px;
        button {
            height: 32px;
            padding-inline: 10px;
            border-radius: 7px;
        }
    }
    .__board_table {
        width: calc(100% - 10px) !important;
        min-width: auto;
        max-width: calc(100% - 10px);
        max-height: 80vh;
        background: #333645;
        margin-left: 5px;
        padding: 0;
        border-radius: 15px;

        .__board_table_legend {
            padding: 5px;
            padding-inline: 10px;
        }
        .__board_table_data {
            padding-inline: 10px;
            height: fit-content;
            min-height: calc(50vh - 100px);
            padding-bottom: 30px;
            .__board_table_data_item {
                &:hover {
                    background: none;
                }
                border-bottom: 1px solid #3b4053;
            }
        }
    }
    .__board_table_no_data {
        width: 100%;
        background: #232531;
        padding-block: 20px;
        h1 {
            color: #fede67;
        }
        p {
            font-size: .85rem;
            color: #96a6c2;
        }
    }
}