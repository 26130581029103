.app__historyGroups_main {
    width: 100%;
    height: fit-content;
    font-family: "Outfit", sans-serif;
    background: #232531;
    display: flex;
    flex-direction: column;
    align-items: center;

    header {
        position: relative;
        width: 100%;
        min-height: 150px;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #f5f5f7;
        font-size: 60px;
        font-family: "GT walsheim bold", sans-serif;
        letter-spacing: -2.4px;
        text-align: center;
        padding-bottom: 10px;

        h3 {
            font-size: 3rem;
        }

        p {
            font-size: 14px;
            font-weight: 300;
            font-family: "SF-Pro", sans-serif;
            letter-spacing: 0;
            color: #9aabc9;
        }
        .app__historyGroups_data_filter {
            position: absolute;
            left: 0;
            bottom: 3px;
            width: 350px;
            height: 60px;

            span {
                width: 100%;
                height: 30%;
                font-size: 1rem;
                font-weight: 300;
                margin-left: 10px;
                display: flex;
                align-items: center;
            }
            .app__historyGroups_data_filter_content {
                width: 100%;
                height: 70%;
                background: #f2930e;
            }
        }
    }
}

.app__historyGroups_data {
    width: 100%;
    overflow-x: auto;
    height: 65%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &::-webkit-scrollbar {
        height: 7px;
    }
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
        background: #f2930e;
    }
}
.app__historyGroups_historyItem {
    width: 90%;
    height: 50px;
    background: rgb(206, 206, 240);
    margin-top: 2px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.app__historyGroups_pageChange {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin-bottom: 15px;
    margin-top: 5px;
    user-select: none;

    .app__historyGroups_pageChange-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        height: 100%;
        padding-inline: 50px;
        position: relative;
        //background: linear-gradient(to right, transparent, #333645, transparent);

        .page_change_pages {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 2px;
            background: #333645;
            border-radius: 7px;
        }

        .active {
            font-size: 1.3rem;
            font-weight: 700;
            background: #5b52f7;
            &:hover {
                background: #5b52f7;
            }
        }

        .page_change_arrows {
            background: #484c62 !important;
            &:hover {
                background: #5b52f7 !important;
            }
        }

        button {
            border: none;
            width: 40px;
            height: 40px;
            border-radius: 5px;
            font-size: 1.1rem;
            font-weight: 200;
            background: transparent;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            transition: background 0.3s ease-in-out;

            &:hover {
                cursor: pointer;
                background: #484c62;
            }
        }
    }
}

.app__sponsors_line-wrapped {
    width: 100%;
    height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #232531;

    .app__sponsors_line {
        max-width: 1600px;
        width: calc(100% - 30px);
        height: 100px;
        margin-top: 10px;
        background: white;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        clip-path: polygon(30px 0%, 100% 0%, 100% calc(100% - 30px), calc(100% - 30px) 100%, 0% 100%, 0% 30px);
    }
}

.__board_table_race_type {
    display: inline-block;
    max-width: 60%;
    padding: 3px 15px;
    border-radius: 5px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    p {
        margin: 0;
        color: #232531;
    }
}


@media (max-width: 1620px) {
    .app__historyGroups_pageChange {
        align-self: flex-start;
    }
}

@media (max-width: 850px) {
    .app__historyGroups_pageChange {
        padding-bottom: 80px;
    }
}