
.app_stash {
    width: 100%;
    height: fit-content;
    min-height: 900px;
    background: #232531;
    display: flex;
    justify-content: center;
    font-family: "SF-Pro", sans-serif;
}

.app_stash_container {
    width: 100%;
    max-width: 1600px;
    height: 100%;
    color: white;
    header {
        width: 100%;
        min-height: 150px;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        font-family: "GT walsheim bold", sans-serif;
        letter-spacing: 0px;
        padding-bottom: 10px;
        h2 {
            font-size: 3rem;
            font-weight: 700;
            text-align: center;
        }
        p {
            font-size: .9rem;
            color: #9aabc9;
        }
    }
}

.app_stash_filter {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    padding-inline: 10px;
    input {
        border: none;
        padding-left: 10px;
        width: 100%;
        max-width: 300px;
        height: 100%;
        border-radius: 5px;
    }
}

.app_stash_folder {
    width: 100%;
    height: 35px;
    display: flex;
    align-items: center;
    gap: 15px;
    cursor: pointer;
    text-align: center;
    padding: 10px;
    &:hover {
        background: #1e293e;
    }
}

.app_stash_folders_legend {
    width: calc(100% - 20px);
    padding-inline: 10px;
    text-transform: uppercase;
    color: #9fb1cf;
}

.app_stash_folders {
    width: calc(100% - 20px);
    padding: 10px;
    max-height: 900px;
    overflow-y: auto;
    table {
        width: 100%;
        padding-inline: 10px;
        border-collapse: collapse;
        thead {
            border-bottom: 1px solid #213657;
            th {
                font-weight: 400 !important;
            }
        }
        th {
            text-align: left;
        }
        td {
            min-width: fit-content;
            padding-block: 3px;
            border-bottom: 1px solid #1e293e;
            overflow: hidden;
            text-wrap: nowrap;
            text-overflow: ellipsis;
        }
        button {
            border: none !important;
            width: fit-content;
            padding: 8px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;

            cursor: pointer;
            color: white;
            background: #5b52f7;
            &:hover {
                transform: scale(0.97);
            }
        }
    }
}
.pdf-preview-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: 999;
    background: #232531;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(5px);
}
.pdf-preview {
    width: 80%;
    height: calc(100% - 40px);
    max-width: 1000px;
    background: #333645;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 90%;
    max-height: 90%;
    overflow: auto;
    .pdf-preview-actions {
        display: flex;
        button {
            border: none;
            margin-top: 10px;
            border-radius: 5px;
            height: 30px;
            cursor: pointer;
            color: white;
            background: #5b52f7;
            padding-inline: 20px;
            margin-right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            &:hover {
                transform: scale(0.97);
            }
        }
    }

    h3 {
        height: 30px;
        width: 100%;
        text-align: center;
        color: white;
        font-weight: 700;
        font-size: 1rem;
        font-family: "SF-Pro-Bold", sans-serif;
    }
}

.pdf-viewer-container {
    width: 100%;
    height: calc(100% - 70px);
    overflow: auto;
}

.app_stash_file_icon {
    display: flex;
    align-items: center;
    gap: 5px;
}

.app_stash_file_actions {
    display: flex;
    align-items: center;
    gap: 5px;

    button {
        border: none;
        //color: white;
        height: 35px;
        border-radius: 7px;
    }
}

@media (max-width: 640px) {
    .app_stash_folder {
        width: 100%;
        height: 150px;
    }
}