
.cursor-hidden {
    cursor: none;
}

@keyframes loader {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}

.app_liveList-fullscreen {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99999;
    background: #232531;
    overflow: hidden;
    transition: background 0.3s ease-in-out;

    header {
        width: 100%;
        height: 150px;
        color: azure;
        display: flex;
        position: relative;
        font-family: "SF-Pro", sans-serif;

        .app_liveList-fullscreen-type {
            position: absolute;
            left: 10px;
            bottom: 10px;
            color: azure;
            font-size: 1.2rem;
            font-weight: 800;
        }

        .app_liveList-fullscreen-track-name {
            margin-left: 10px;
            margin-top: 10px;
            display: flex;
            flex-direction: column;
            p {
                font-size: 2.5rem;
                font-weight: 600;
            }
        }
        .app_liveList-fullscreen-local-time {
            position: absolute;
            top: 55px;
            right: 120px;
            width: 200px;
            font-weight: 800;
            letter-spacing: 1px;
            font-size: 1.3rem;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            p {
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        .app_liveList-fullscreen-time {
            position: absolute;
            bottom: 2px;
            left: 50%;
            width: 350px;
            transform: translate(-50%);
            height: 34px;
            font-weight: 800;
            letter-spacing: 1px;
            font-size: 1.3rem;
            p {
                width: 100%;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        .app_liveList-fullscreen-flag-line {
            position: absolute;
            left: 10px;
            bottom: 0px;
            width: calc(100% - 20px);
            height: 4px;
        }
        .app_liveList-mode-toggle {
            position: absolute;
            right: 200px;
            top: 10px;
            width: 40px;
            height: 40px;
            border-radius: 7px;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #f1ba22;
            cursor: pointer;
        }
        .app_liveList-fullscreen-toggle {
            position: absolute;
            right: 150px;
            top: 10px;
            width: 40px;
            height: 40px;

            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;

            border: none;
            color: #fff;
            font-size: 14px;
            background: #aa74f2;
            border-radius: 7px;
            font-weight: 400;
            cursor: pointer;

            &:hover {
                cursor: pointer;
                transform: scale(0.97);
                background: #14396d;
                transition: background 0.5s ease-in-out;
            }
        }
        .app_liveList-fres-by-class-toggle {
            position: absolute;
            right: 60px;
            top: 10px;
            width: 150px;
            height: 40px;

            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            background: #0a0a0d !important;
            border-radius: 7px;
            color: #fff;
            font-size: 14px;
            font-weight: 400;
            cursor: pointer;

            &:hover {
                transition: background 0.5s ease-in-out;
                background: #0b4699;
            }
        }
    }
    .app_liveList-fullscreen-loading {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        color: #b4f3fa;
        font-family: "SF-Pro", sans-serif;
        font-size: 1.2rem;
        font-weight: 500;
        letter-spacing: 1.3px;
    }
    .app_liveList-fullscreen-data {
        width: 100%;
        height: 70%;
        font-family: "SF-Pro", sans-serif;

        .app_liveList-fullscreen-pagetime {
            width: calc(100% - 20px);
            height: 7px;
            margin-left: 10px;
            .app_liveList-fullscreen-pagetime-loader {
                height: 100%;
                background: #5b5867;
                animation: loader 11s linear infinite;
            }
        }

        .app_liveList-fullscreen-comp-data {
            width: calc(100% - 20px);
            height: calc(100% - 35px);
            margin-left: 10px;

            .app_liveList-fullscreen-percentage {
                width: 100%;
                height: 2px;
                background: #232531;
                .app_liveList-fullscreen-percenage-bar {
                    height: 100%;
                    background: #2febf5;
                    transition: width 1s linear;
                }
            }

            .app_liveList-fullscreen-item {
                width: 100%;
                height: 36px;
                //background: #1c1b1b;
                margin-top: 1px;
                display: flex;
                align-items: center;
                transition: background-color 1s ease;

                .app_liveList-fullscreen-pos {
                    width: 6%;
                    height: 100%;
                    border-right: 1px solid #000;
                    display: flex;
                    align-items: center;
                    .app_liveList-fullscreen-pos-inner {
                        display: flex;
                        align-items: center;
                        gap: 8px;

                        font-weight: 900;
                        font-size: 1.4rem;
                        height: 100%;
                        margin-left: 15px;
                        .app_liveList-fullscreen-pos-inner-lower {
                            height: 50%;
                            margin-top: 10%;
                            display: flex;
                            gap: 5px;
                            align-items: center;
                            p {
                                font-size: 1.2rem;
                                font-weight: 500;
                            }
                            span {
                                font-weight: 500;
                            }
                        }
                        span {
                            font-size: 1.1rem;
                        }
                    }
                }
                .lastname_bold {
                    font-size: 1.25rem !important;
                    font-weight: 800 !important;
                    font-family: "SF-Pro-Bold", sans-serif !important;
                }
                .app_liveList-fullscreen-nr-light {
                    width: 3%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    color: #37088d;
                    justify-content: center;
                    font-size: 1.2rem;
                    font-family: "SF-Pro-Bold", sans-serif;
                    font-weight: 900;
                    border-right: 1px solid #000;
                }
                .app_liveList-fullscreen-nr {
                    width: 3%;
                    height: 100%;
                    border-right: 1px solid #000;
                    color: #85a0ff;
                    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 1.2rem;
                    font-family: "SF-Pro-Bold", sans-serif;
                    font-weight: 900;
                }
                .app_liveList-fullscreen-5 {
                    width: 5%;
                    height: 100%;
                    border-right: 1px solid #000;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 1.1rem;
                    text-align: center;
                    gap: 5px;
                    img {
                        width: calc(50% - 3px);
                        max-width: 40px;
                        height: 60%;
                        max-height: 30px;
                    }
                }
                .app_liveList-fullscreen-position-c {
                    width: 3%;
                    height: 100%;
                    border-right: 1px solid #000;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
                    img {
                        width: 22px;
                        height: 22px;
                    }
                    span {
                        font-size: 1.3rem;
                        font-weight: 700;
                    }
                }
                .app_liveList-fullscreen-20 {
                    width: calc(23% - 10px);
                    height: 100%;
                    border-right: 1px solid #000;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    text-wrap: nowrap;
                    display: flex;
                    align-items: center;
                    padding-inline: 5px;
                    font-size: 1.2rem;
                    text-align: center;
                  }
                .app_liveList-fullscreen-10 {
                    width: 10%;
                    height: 100%;
                    border-right: 1px solid #000;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 1rem;
                    text-align: center;

                    p {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        text-wrap: nowrap;
                        font-size: 1.1rem;
                    }
                }
                .app_liveList-fullscreen-8 {
                    width: 8%;
                    height: 100%;
                    border-right: 1px solid #000;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 0.9rem;
                    text-align: center;

                    p {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        text-wrap: nowrap;
                        font-size: 1.1rem;
                    }
                }
                .app_liveList-fullscreen-7 {
                    width: 7%;
                    height: 100%;
                    border-right: 1px solid #000;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 0.9rem;
                    text-align: center;

                    p {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        text-wrap: nowrap;
                        font-size: 1.20rem;
                    }
                }
                .app_liveList-fullscreen-6 {
                    width: 6%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 0.9rem;
                    text-align: center;
                }
            }

        }

        .app_liveList-fullscreen-legend {
            height: 35px;
            width: calc(100% - 20px);
            //background: #393841;
            display: flex;
            margin-top: 3px;
            margin-left: 10px;

            .app_liveList-fullscreen-legend-item {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                border-right: 1px solid #232531;
                font-size: .9rem;
                &:nth-last-child(1) {
                    border-right: none;
                }
            }
        }

    }
}

.fullscreen_timer {
    display: flex;
    gap: 5px;
    align-items: center;
}

.app_liveList-fullscreen-footer {
    width: 100%;
    height: 98px;
    background: #333645;
    color: white;
    position: absolute;
    bottom: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.app_liveList-qr-message {
    position: absolute;
    right: 150px;
    bottom: 10px;
    font-size: .8rem;
}

.app_liveList-qr {
    position: absolute;
    right: 10px;
    top: 10px;
}

.app_liveList-fullscreen-classname {
    width: 100%;
    height: 30px;
    background: #e43e62;

    display: flex;
    align-items: center;
    justify-content: center;

    p {
        color: azure;
        font-size: 1.5rem;
        font-family: "SF-Pro", sans-serif;
        letter-spacing: 1.2px;
        text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    }
}
