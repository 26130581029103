

.app__historyEvent-main {
    width: 100%;
    height: fit-content;
    font-family: "Outfit", sans-serif;
    background: #232531;
    display: flex;
    flex-direction: column;
    align-items: center;

    header {
        width: 100%;
        min-height: 150px;
        padding-bottom: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #f5f5f7;
        font-size: 60px;

        font-family: "GT walsheim bold", sans-serif;
        letter-spacing: 0px;
        text-align: center;

        h3 {
            font-size: 3rem;
        }

        p {
            font-size: 20px;
            font-weight: 300;
            font-family: "SF-Pro", sans-serif;
            letter-spacing: 0;
            color: #9fb1cf;
        }
    }
}